import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';

import Breadcrumbs from '../../../../components/Breadcrumbs';
import YoutubeIcon from '../../../../components/icons/YoutubeIcon';

import Link from '../../../../components/Link';
import Seo from '../../../../components/Seo';

const KripatskiPisniPage = ({ pageContext }) => {
  const data = useStaticQuery(graphql`
    query {
      songs: allSong(filter: {category: {eq: "kripatski-pisni"}}, sort: {fields: title}) {
        nodes {
          title
          slug
          hasYoutube
        }
      }
    }
  `);

  const songs = data.songs.nodes;

  return (
    <>
      <Seo
        title="Кріпацькі пісні"
        description="Кріпацькі пісні співали переважно кріпаки — люди, прикріплені до земельної ділянки, без права власності на неї, результати праці цих людей собі забирав поміщик."
      />
      <div className="bg-red-500 text-white">
        <div className="container-lg py-10 md:py-24">
          <Breadcrumbs
            crumbs={pageContext.breadcrumb.crumbs}
          />
          <h1 className="typo-h1 lowercase mt-4">
            Кріпацькі пісні
          </h1>
          <p className="typo-body mt-4 max-w-2xl">
            Кріпацькі пісні співали переважно кріпаки — люди, прикріплені до земельної ділянки, без права власності на
            неї, результати праці цих людей собі забирав поміщик.
          </p>
        </div>
      </div>
      <div className="container-lg mt-10 md:mt-24">
        <h2 className="typo-h2">
          Список кріпацьких пісень
        </h2>
        <ul className="row mt-8">
          {songs.map((song) => (
            <li className="col-full md:col-4 mb-4">
              <Link to={song.slug} className="typo-small flex items-center hover:text-red-500">
                {song.title}
                {song.hasYoutube && (
                  <YoutubeIcon className="ml-2 w-4 h-4" />
                )}
              </Link>
            </li>
          ))}
        </ul>
      </div>
      <div className="container-lg py-10 md:py-24">
        <h2 className="typo-h2">
          Опис жанру кріпацької пісні
        </h2>
        <p className="typo-body mt-4">
          Кріпацькі пісні вражають силою реалістичного зображення тяжкого підневільного життя селян, протесту проти
          приниження людської гідності і безправ’я у його буденних проявах: «осавули з нагаями на панщину гонять» не
          тільки молодих і здорових, але й старих та немічних і навіть малих дітей, а поскаржитися не можна, бо зараз
          «бере савула з козаками по сто палок бити».
        </p>
        <p className="typo-body mt-4">
          Пісні були чи не єдиною розрадою і розвагою кріпака. Чув їх і малий Тарас Шевченко.
        </p>
        <p className="typo-body italic mt-4">
          Ой у неділю ранесенько до церкви дзвони дзвонять,
          <br />
          А нашого вельможного пана десятчики на панщину гонять.
          <br />
          Ой годі вам, препогані хлопи, до церкви ходити,
          <br />
          Ідіть на свого вельможного пана роботу робити!
        </p>
        <p className="typo-body mt-4">
          Може, саме ці рядки зринали у спогадах поета про безщасну рідню, яка гинула в неволі, коди він з болем і
          гнівом писав: «у тій хатині, у раю я бачив пекло. Там неволя, робота тяжкая, ніколи й помолитись не дають»..
        </p>
        <p className="typo-body mt-4">
          В піснях кріпацького циклу можна простежити наростання бунтівничих настроїв кріпаків — від скарг і прокльонів,
          які, за словами великого Кобзаря, були наївною, невинною помстою, «налож, пане, головою із твоєю роботою» до
          усвідомлення того, що пани живуть працею кріпаків,— «а хто ж буде лани жати, як мене не стане?!», до прямих
          закликів до боротьби:
        </p>
        <p className="typo-body italic mt-4">
          Ой ходімо, пане-брате, в степ та в гайдамаки,
          <br />
          Та дамося, пане-брате, добре донам взнаки!
        </p>
        <p className="typo-body mt-4">
          Чутки про скасування кріпацтва породили низку сатиричних пісень, що не втрачали популярності впродовж багатьох
          років, коли панщина вже не існувала. На думку І. Франка, це свідчило «про вищий розвій народного самопізнання,
          коли народ зачинає критично розглядати суспільний устрій, який його оточує, а не сміючи, або й не вміючи
          висловити своїх вражень просто, висловлює їх гумористично-іронічним способом в їдких, насмішливих пісеньках»
        </p>
        <p className="typo-body mt-4">
          Надії колишніх кріпаків не справдилися, а пекуче бажання «озути пана в постоли» витворило пісні, в яких
          народна уява малювала сатиричні картинки: «пішла наша бариня пшениченьку жати, а за нею паничі колосся збирати»
        </p>
        <p className="typo-body mt-4">
          Продовжуючи традиції родинно-побутової лірики, цей пісенний цикл розширює її тематичні рамки, торкається
          оцінки явищ соціального порядку.
        </p>
      </div>
    </>
  );
};

export default KripatskiPisniPage;
